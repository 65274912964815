.pre-header {
	box-sizing: border-box;
	color: rgb(232, 230, 227);
	background-color: rgb(41, 44, 46);
}

.pre-header-social {
	display: flex;
	flex-direction: row-reverse;
}

.pre-header-social-link {
	font-size: 25px;
	padding: 5px;
}

.insta-color {
	color: #ffc838;
}

.fb-color {
	color: #1778f2;
}

.youtube-color {
	color: #c4302b;
}

.twitter-color {
	color: #1da1f2;
}

.google-color {
	color: #3cba54;
}

.yelp-color {
	color: #d32323;
}
