h2 {
	text-align: center;
}
.pt-label {
	text-align: center !important;
}

.pricing__section {
	padding: 100px 0 160px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #1c2237;
}

.pricing__container-card {
	background: linear-gradient(45deg, #1c2237 0%, #1c2237 100%);
	box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
	width: 480px;
	height: 920px;
	text-decoration: none;
	border-radius: 4px;
}
/* middle pricing card size up difference */
/* .pricing__container-card:nth-child(2) {
	transform: scale(1.05);
	background: #242424;
} */

.pricing__container-card:hover {
	transform: scale(1.06);
	transition: all 0.3s ease-out;
	color: #1c2237;
}

.pricing__container-card:nth-child(2) {
	margin: 0 24px;
}

.pricing__container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.pricing__wrapper {
	display: flex;
	flex-direction: column;
	align-items: start;
	margin: 0 auto;
}

.pricing__heading {
	text-align: center;
	color: #1c2237;
	margin-bottom: 24px;
	font-size: 70px;
}

.pricing__container-cardInfo {
	display: flex;
	flex-direction: column;
	height: 860px;
	/* height: 500px; */
	padding: 24px;
	align-items: center;
	color: #fff;
}

.pricing__container-cardInfo h3 {
	text-align: center;
	margin-bottom: 5px;
	font-size: 24px;
}

.pricing__container-cardInfo h4 {
	font-size: 40px;
}

.pricing__container-cardInfo p {
	font-size: 14px;
	margin-bottom: 24px;
}

.pricing__container-features {
	margin: 16px 0 32px;
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.pricing__container-features li {
	text-align: center;
	border-bottom: 1px solid #eee;
	margin-bottom: 10px;
	padding: 10px;
	font-size: 1.5rem;
}

.icon {
	margin: 24px 0;
}

@media screen and (max-width: 960px) {
	.pricing__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.pricing__container-card {
		width: 90%;
	}

	.pricing__wrapper {
		margin: 0 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.pricing__container-card:nth-child(2) {
		transform: scale(1);
		background: #1c2237;
		margin: 24px;
	}

	.pricing__container-card:hover {
		transform: none;
	}

	.pricing__container-cardInfo {
		height: 900px;
	}
}
