h1 {
	text-align: center;
	padding: 50px;
	color: #fff;
}
.bg-whatWeDo {
	background-color: #1c2237;
	height: 115vh;
}

.content-holder {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 20px 0;
	color: #fff;
}

.heading-section {
	text-align: center;
	margin-top: 20px;
}

.text-div {
	letter-spacing: 1px;
	text-align: left;
	padding-top: 20px;
}
.weird-pad {
	padding-top: 40px;
}

/* On Mobiles (screen width <= 767px) */
@media screen and (max-width: 960px) {
	.content-holder {
		flex-direction: column;
	}

	.text-div {
		margin-left: 10px;
	}

	.weird-pad {
		padding-top: 20px;
	}
}
