h1 {
	text-align: center;
	padding: 50px;
	color: #fff;
}
h3 {
	text-align: center;
	color: #fff;
}
h4 {
	text-align: center;
	color: #fff;
}

.refer-bg {
	background-color: #1c2237;
	height: 160vh;
}

@media screen and (max-width: 960px) {
	.refer-bg {
		background-color: #1c2237;
		height: 220vh;
	}
}
