.prev-btn {
	width: 146px;
	height: 40px;
}

@media screen and (max-width: 440px) {
	.mobile-btn {
		width: 105px;
		height: 40px;
		font-size: 11px;
	}
}
