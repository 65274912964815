h1 {
	color: #fff;
}
.form-bg-color {
	background-color: #dbf0f9;
}

.pad-bottom {
	padding-bottom: 30px;
}

.bg-color {
	background-color: #2d3745 !important;
}

@media screen and (max-width: 440px) {
	.pad-top {
		padding-top: 30px;
	}
}
