h1 {
	text-align: center;
	padding: 50px;
	color: #fff;
}

.quote-bg {
	background-color: #1c2237;
	height: 120vh;
}

@media screen and (max-width: 960px) {
	.quote-bg {
		background-color: #1c2237;
		height: 150vh;
	}
}
