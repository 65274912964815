hr {
	display: block;
	margin-top: 1.5em;
	margin-bottom: 0.5em;
	margin-left: auto;
	margin-right: auto;
	border-style: inset;
	border-width: 1px;
	width: 65%;
}

h2 {
	font-size: 20px;
	text-align: justify;
	font-weight: bold;
	color: #fff;
}

.next-btn {
	width: 146px;
	height: 40px;
}
